import {notification} from "antd";
import {mainProgramAppApi} from "services/api/api-instance";
import type {
  EducationLevel,
  MainProgramBlockDtoBody,
  GetCurriculumsByEducationLevelResponse,
  ListResponse,
  CurriculumDetailed,
  MainProgram,
  MainProgramBlockDto,
  ProgramTemplate,
  WorkProgram,
  GetCurriculumsByEducationLevelRequest,
  MainProgramItem,
  GetMainProgramListPayload,
  GetWorkProgramsPayload,
  SetWorkProgramAvailableInMainProgramPayload,
  Subject,
  ToggleVisibleWorkProgramInMainProgramListPayload
} from "services/api";
import type {Curriculum,CreateMainProgramBody} from "./types";
import {filterEmptyTables, getUniqueItems} from "./utils";

export const DEFAULT_LIMIT = 10;

export const getCurriculumsByEducationLevel = (body: GetCurriculumsByEducationLevelRequest): Promise<ListResponse<Curriculum>> => {
  const {educationLevel, page, searchQuery} = body;
  return mainProgramAppApi
      .get<GetCurriculumsByEducationLevelResponse>(`curriculum/list?educationLevel=${educationLevel}&page=${page}&name=${searchQuery}&limit=${DEFAULT_LIMIT}`)
      .then(res => {
        return {
          items: res.data.content,
          total: res.data.total,
        };
      })
      .catch(error => {
        notification.error({message: "Не удалось загрузить учебные планы"});
        throw error;
      });
};

export const getWorkProgramsBySubjectId = (subjectId: number, educationLevel: EducationLevel, organizationId?: number): Promise<WorkProgram[]> => {
  return mainProgramAppApi
      .get(`work_program/by_organization?subjectId=${subjectId}&educationLevel=${educationLevel}&organizationId=${organizationId}`)
      .then(res => res.data)
      .catch(error => {
        notification.error({message: "Не удалось загрузить рабочие программы"});
        throw error;
      });
};

export const getWorkPrograms = ({educationLevel, organizationId, page, sort, limit, subjectId, showHidden}: GetWorkProgramsPayload): Promise<ListResponse<WorkProgram>> => {
  let sortString = "";
  if (sort) {
    sortString = sort.reduce((acc, current) => {
      acc = acc + `&sort=${encodeURIComponent(current)}`;

      return acc;
    }, "");
  }

  const subjectIdString = subjectId ? `&subjectId=${subjectId}` : "";
  return mainProgramAppApi
    .get(`work_program/by_organization_id?page=${page}&limit=${limit}&showHidden=${showHidden}&educationLevel=${educationLevel}&organizationId=${organizationId}${sortString}${subjectIdString}`)
    .then(({data, headers}) => {
      return {
        items: data,
        total: Number(headers["totalelements"]) || 0
      };
    })
    .catch(error => {
      notification.error({message: "Не удалось загрузить рабочие программы"});
      throw error;
    });
};

export const getAllSubjects = (): Promise<Subject[]> => {
  return mainProgramAppApi
    .get("subject/list")
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось загрузить предметы"});
      throw error;
    });
};

export const createMainProgram = (body: CreateMainProgramBody): Promise<any> => {
  return mainProgramAppApi
    .post("main_program/", body)
    .catch(error => {
      notification.error({message: "Не удалось создать ООП"});
      throw error;
    });
};


export const getMainPrograms = ({isTemplate, limit, page, sort}: GetMainProgramListPayload): Promise<ListResponse<MainProgram>> => {
  let sortString = "";

  if (sort) {
    sortString = sort.reduce((acc, current) => {
      acc = acc + `&sort=${encodeURIComponent(current)}`;

      return acc;
    }, "");
  }
  return mainProgramAppApi
    .get(`main_program/list?isTemplate=${!!isTemplate}&limit=${limit}&page=${page}${sortString}`)
    .then(({data, headers}) => {
      return {
        items: data,
        total: Number(headers["totalelements"]) || 0
      };
    })
    .catch(error => {
      notification.error({message: "Не удалось загрузить ООП"});
      throw error;
    });
};

export const setWorkProgramAvailableInMainProgram = ({programId, isAvailable}: SetWorkProgramAvailableInMainProgramPayload): Promise<number> => {
  return mainProgramAppApi
    .put(`work_program/set_show_in_main_programs/${programId}?show_in_main_program=${isAvailable}`)
    .then(() => {
      return programId;
    })
    .catch(error => {
      notification.error({message: "Ошибка сервера"});
      throw error;
    });
};

export const toggleVisibleWorkProgramInMainProgramList = ({programId, isHidden}: ToggleVisibleWorkProgramInMainProgramListPayload): Promise<number> => {
  return mainProgramAppApi
    .put(`work_program/set_hide_for_manager_in_main_program/${programId}?hide_for_manager=${isHidden}`)
    .then(() => {
      return programId;
    })
    .catch(error => {
      notification.error({message: "Ошибка сервера"});
      throw error;
    });
};

export const deleteMainProgram = (id: number): Promise<any> => {
  return mainProgramAppApi
    .delete(`main_program/${id}`)
    .catch(error => {
      notification.error({message: "Не удалось удалить ООП"});
      throw error;
    });
};

export const deleteMainProgramItem = (id: number): Promise<any> => {
  return mainProgramAppApi
  .delete(`main_program_item/${id}`)
  .catch(error => {
    notification.error({message: "Не удалось удалить элемент"});
    throw error;
  });
};

export const updateMainProgramItem = (id: number, mainProgramItem: MainProgramItem): Promise<MainProgramItem> => {
  return mainProgramAppApi
    .put(`main_program_item/${id}`, mainProgramItem)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось обновить элемент"});
      throw error;
    });
};

export const updateMainProgram = (mainProgram: MainProgram): Promise<any> => {
  return mainProgramAppApi
    .put("main_program/", mainProgram)
    .catch(error => {
      notification.error({message: "Не удалось обновить ООП"});
      throw error;
    });
};

export const getMainProgram = (id: string): Promise<MainProgram> => {
  return mainProgramAppApi
    .get(`main_program/${id}`)
    .then(res => {
      return {
        ...res.data,
        items: getUniqueItems(res.data.items).map((item: MainProgramItem) => {
          return {
            ...item,
            index: 0,
          };
        }),
        curriculums: res.data.curriculums.map((item: Curriculum) => {
          return {
            ...item,
            index: 1,
          };
        }),
      };
    })
    .catch(error => {
      notification.error({message: "Не удалось загрузить ООП"});
      throw error;
    });
};

export const getWorkProgramById = (partId: string): Promise<ProgramTemplate> => {
  return mainProgramAppApi
    .get(`/work_program/${partId}`)
    .then(res => filterEmptyTables(res.data))
    .catch(error => {
      notification.error({message: "Ошибка загрузки"});
      throw error;
    });
};

export const getMainProgramBlockById = (blockId: string): Promise<MainProgramBlockDto> => {
  return mainProgramAppApi
    .get(`/main_program_block/${blockId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка загрузки"});
      throw error;
    });
};

export const getDetailedCurriculumById = (curriculumId: string, mainProgramId: number): Promise<CurriculumDetailed> => {
  return mainProgramAppApi
    .get(`/curriculum/detailed/${curriculumId}?programId=${mainProgramId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка загрузки"});
      throw error;
    });
};

export const createMainProgramBlock = (body: MainProgramBlockDtoBody): Promise<MainProgramBlockDto> => {
  return mainProgramAppApi
    .post("main_program_block/", body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось создать блок"});
      throw error;
    });
};

export const removeMainProgramBlock = (id: number): Promise<any> => {
  return mainProgramAppApi
    .delete(`main_program_block/${id}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось удалить блок"});
      throw error;
    });
};

export const updateMainProgramBlock = (body: MainProgramBlockDtoBody): Promise<MainProgramBlockDto> => {
  return mainProgramAppApi
    .put("main_program_block/", body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось обновить блок"});
      throw error;
    });
};

export const renameCurriculum = (id: number, newName: string): Promise<Curriculum> => {
  return mainProgramAppApi
    .post(`/curriculum/rename/${id}?newName=${newName}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось переименовать учебный план"});
      throw error;
    });
};

export const convertMainProgramToDocx = (programId: string, start: string, finish: string): Promise<void> => {
  const bodyFormData = new FormData();
  const startFile = new Blob([start], {type : "plain/text"});
  const finishFile = new Blob([finish], {type : "plain/text"});
  bodyFormData.append("start", startFile);
  bodyFormData.append("finish", finishFile);

  return mainProgramAppApi
    .post(`main_program/convert/${programId}`, bodyFormData, {
      headers: {"Content-Type": "multipart/form-data"}
    })
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось сгенерировать документ"});
      throw error;
    });
};

export const checkConvertedDocx = (programId: string, showError = true): Promise<"DONE" | "NEW" | "ERROR" | "PROGRESS"> => {
  return mainProgramAppApi
    .get(`/main_program/convert/check/${programId}`)
    .then(res => res.data)
    .catch(error => {
      if (showError) {
        if (error?.response?.status === 404) {
          notification.error({message: "Необходимо сгенерировать документ"});
        } else {
          notification.error({message: "Ошибка"});
        }
      }
      throw error;
    });
};

export const getConvertedDocument = (programId: string, type: "pdf" | "docx"): Promise<Blob> => {
  return mainProgramAppApi
    .get(`/main_program/convert/${type}/${programId}`, {responseType: "blob"})
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка"});
      throw error;
    });
};
